body {
  margin: 0;
  background-color: #22242b;
  font-family: Montserrat, sans-serif;
}

section {
  display: block;
  position: relative;
  padding: 80px 30px;
  border-bottom: 0px none #e4ebf3;
  background-color: #22242b;
}

a {
  font-weight: 600;
  background-color: transparent;
  transition: opacity 200ms ease;
  color: #31518b;
  text-decoration: none;
  mix-blend-mode: normal;
}

a:active,
a:hover {
  outline: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

p {
  margin-top: 6 0;
  margin-bottom: 24px;
  padding: 20px 30px;
  align-self: auto;
  color: #a8c4e5;
  text-align: center;
}

.logo-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0px;
  padding-bottom: 20px;
}

.hero-wrapper {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}

.hero-split {
  display: flex;
  max-width: 46%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.shadow {
  max-height: none;
  max-width: 100%;
  box-shadow: 0 4px 24px 0 rgba(168, 196, 229, 0.08);
  mix-blend-mode: difference;
}

@media screen and (max-width: 991px) {
  .logo-container {
    max-width: 728px;
  }

  p {
    text-align: center;
  }

  .hero-heading {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .hero-wrapper {
    display: flex;
    max-width: 728px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: -40px;
  }

  .hero-split {
    max-height: none;
    max-width: 100%;
    margin-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .shadow {
    max-height: 200px;
    flex: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .logo-container {
    padding: 60px 15px;
    justify-content: center;
  }

  .hero-wrapper {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 479px) {
  .logo-container {
    max-width: none;
  }

  .hero-wrapper {
    max-width: none;
  }

  p {
    overflow: visible;
    color: #e2e4e1;
  }
}